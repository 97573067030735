@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
}

input {
  outline: none;
}

input:focus {
  outline: none;
}

.scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #4b465c #f1f1f1;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #4b465c;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-heading {
  @apply font-semibold text-[22px] leading-[30px] text-[#4B465C];
}

.divider::before {
  content: "";
  position: absolute;
  top: 0;
  left: -24px;
  width: 1px;
  height: 100%;
  background-color: #DBDADE;
}

.dropdown {
  @apply rounded-md border border-[#DBDADE] py-[7px] px-[14px]
}

.opened-model {
  overflow: hidden;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #eee;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border-bottom: 4px solid #FFD700;
  border-left: 4px solid transparent;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}